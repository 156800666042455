import React from "react";
import "../styles/Projects.css";
import FolderOpenRoundedIcon from "@material-ui/icons/FolderOpenRounded";
import GitHubIcon from "@material-ui/icons/GitHub";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import FadeInSection from "./FadeInSection";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from "react-bootstrap/Carousel";
import ExternalLinks from "./ExternalLinks";

class Projects extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1"
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey
    });
  }
  render() {
    const spotlightProjects = {
      "Timely": {
        title: "timely",
        desc:
          "A revolutionary chrome extension used to optimize users Google Maps Routes to save them time, money, and CO2 emissions.",
        techStack: "HTML, CSS, JS, Node.JS",
        link: "https://github.com/CyrilKamal/Timely",
        open: "https://chrome.google.com/webstore/detail/timely/mnbegbhknalbfphkepeodjnagjgiemca",
        image: "/assets/timely3.png"
      },
      "Disney+ Clone": {
        title: "disney+ clone",
        desc:
          "A clone of Disney+ meant to represent the frontend of a potential streaming service.",
        techStack: "REACTJS, FIREBASE, REDUX",
        link: "https://github.com/CyrilKamal/DisneyClone",
        open: "https://disneyplus-clone-8593a.web.app/",
        image: "/assets/dplus.png"
      },
      OPLE: {
        title: "ople",
        desc:
          "My variation of wordle where all the words in the game are related to one piece.",
        techStack: "HTML, CSS, JS",
        link: "https://github.com/CyrilKamal/Ople",
        open: "https://playful-longma-e9a70b.netlify.app/",
        image: "/assets/ople.png"
      },
      "Pathfinding Visualizer": {
        title: "pathfinding visualizer",
        desc:
          "A tool used to visualize different pathfinding algorithms with different tools to experiment with.",
        techStack: "HTML, CSS, JS",
        link: "https://github.com/CyrilKamal/Pathfinding-Visualizer",
        open: "https://cyrilkamal.github.io/Pathfinding-Visualizer/",
        image: "/assets/pfv.png"
      }
    };
    const projects = {
      "Speech Recognition Website": {
        desc:
          "A website to help users analyze and transcribe audio files with Python, Flask, HTML, and CSS. It also has a GET and POST request on the same route",
        techStack: "Python (Flask), SpeechRecognition API, HTML",
        link: "https://github.com/CyrilKamal/SpeechRecognitionPython",
        open: "http://cyrilkamal.pythonanywhere.com/"
      },
      "Cryptopunk NFT Site": {
        desc:
          "A fullstack NFT website. That encapsulates cutting-edge Web 3.0 and NFT Technologies with an Etherium APP to build this NFT marketplace.",
        techStack: "JS(React), ThirdWeb, NodeJS",
        link: "https://github.com/CyrilKamal/react-nft-project",
        open: "https://www.youtube.com/watch?v=cw5tTRByTMY"
      },
      "Machine Learning Stock Predictor": {
        desc:
          "An application that can help users accurately estimate a stock's price 30 days into the future and display stock price data.",
        techStack: "Python (SKlearn), Quandl API",
        link:
          "https://github.com/CyrilKamal/MachineLearningStockPredictor"
      },
      "RNSRMS": {
        desc:
          "Lead a team to create a Server Rack Managment System software that is an end-to-end solution requiring design, coding, testing and validation. Communications, data management and critical thinking skills were further emphasized in my teams project objectives.",
        techStack: "Javascript, Node.js, Natural NLP, Telegram API",
        link: "https://github.com/SeanKuehl/RNSRMS",
        open: ""
      },
      "COVID-19 Visualizer": {
        desc:
          "An application that creates accessibility for users by displaying up-to-date covid information in a stacked bar chart, pie chart, and line chart.",
        techStack: "Python (Pandas, MatPlotLib)",
        link: "https://github.com/CyrilKamal/Covid19-Visualizer",
        open: ""
      },
      "TCP HTTP Client Server": {
        desc:
          "A TCP HTTP Client Sever application is a simple posting system with a client-server topology. It is intended to recreate a RESTFUL API using socket programming. It has GET, PUT, POST, and DELETE methods with synchronous multiplexing.",
        techStack: "C (Socket Programming)",
        link: "https://github.com/CyrilKamal/TCP-Server-Client",
        open: ""
      }
    };

    return (
      <div id="projects">
        <div className="section-header ">
          <span className="section-title">/ software-creations</span>
        </div>
        <Carousel>
          {Object.keys(spotlightProjects).map((key, i) => (
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={spotlightProjects[key]["image"]}
                alt={key}
              />
              <div className="caption-bg">
                <Carousel.Caption>
                  <h3>{spotlightProjects[key]["title"]}</h3>
                  <p>
                    {spotlightProjects[key]["desc"]}
                    <p className="techStack">
                      {spotlightProjects[key]["techStack"]}
                    </p>
                  </p>
                  <ExternalLinks
                    githubLink={spotlightProjects[key]["link"]}
                    openLink={spotlightProjects[key]["open"]}
                  ></ExternalLinks>
                </Carousel.Caption>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
        <div className="project-container">
          <ul className="projects-grid">
            {Object.keys(projects).map((key, i) => (
              <FadeInSection delay={`${i + 1}00ms`}>
                <li className="projects-card">
                  <div className="card-header">
                    <div className="folder-icon">
                      <FolderOpenRoundedIcon
                        style={{ fontSize: 35 }}
                      ></FolderOpenRoundedIcon>
                    </div>
                    <ExternalLinks
                      githubLink={projects[key]["link"]}
                      openLink={projects[key]["open"]}
                    ></ExternalLinks>
                  </div>

                  <div className="card-title">{key}</div>
                  <div className="card-desc">{projects[key]["desc"]}</div>
                  <div className="card-tech">{projects[key]["techStack"]}</div>
                </li>
              </FadeInSection>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Projects;