import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FadeInSection from "./FadeInSection";

const isHorizontal = window.innerWidth < 600;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  if (isHorizontal) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  } else {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  if (isHorizontal) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`
    };
  } else {
    return {
      id: `vertical-tab-${index}`
    };
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
    display: "flex",
    height: 300
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}));

const JobList = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const experienceItems = {
    Liryc: {
      jobTitle: "Founder and Lead Developer @",
      duration: "JAN 2023 - PRESENT",
      desc: [
        "Led Agile development of Timely, a Chrome extension that optimized Google Maps routes by rearranging waypoints, resulting in a 15% reduction in travel time on average.",
        "Implemented a sophisticated backend algorithm that prioritizes real-time traffic data, which increased user satisfaction by 37% by optimizing the user experience.",
        "Achieved top 100 global ranking in the Google Solution Challenge First Judging Round for Timely, an accomplishment that reflects the product's innovation and potential impact."
      ]
    },
    ETAS: {
      jobTitle: "Software Engineer @",
      duration: "MAY 2023 - DEC 2023",
      desc: [
        "Conceptualized a state of the art cross-functional project, delivering a production-ready solution across multiple System-on-Chips (SoCs). Achieved a 27% reduction in system latency and increased overall system performance by 25%",
        "Implemented a scalable system enhancing operations, accomplishing 30% faster processing and enabling new updates, improving system security and functionality"
      ]
    },
    "SMART Centre": {
      jobTitle: "Research Software Developer @",
      duration: "JAN 2023 - APR 2023",
      desc: [
        "Developed a smart analytical recycling line using advanced machine learning techniques and algorithm design, resulting in a 27% increase in recycling accuracy and cost savings.",
        "Deployed optimized ML script on the company's server farm, cutting computation time by 53% and boosting efficiency."
      ]
    },
    SolidStateAI: {
      jobTitle: "Software Engineer @",
      duration: "MAY 2022 - AUG 2022",
      desc: [
        "Programmed and automated a highly scalable testing infrastructure on SOSCIP supercomputers, utilizing machine learning models (SVM, NN, etc.) to identify inefficiencies and assist in debugging, resulting in improved performance",
        "Accomplished a 28% improvement in efficiency by refactoring AIMS through enhancements to multithreading, frontend design, database indexing, and migration to Docker",
        "Fulfilled an 87% increase in customer satisfaction by producing a new multithreaded structure with optimized algorithms and data collection techniques "
      ]
    },
    CPOS: {
      jobTitle: "Software Engineer @",
      duration: "SEP 2022 - PRESENT",
      desc: [
        "Developed an efficient web application with Flutter and Node.js to handle over 2 million daily requests and 5,000 customers by using interpolation, state management, and creating/integrating APIs ",
        "Integrated third-party fintech systems with the QRTO platform, using Converge to manage online payment, increasing global revenue by 23% in the first quarter.",
        "Increased server query time by 19% by engineering the QRTO system with its six subsystems and its interfaces to external systems by restructuring the API and applying the Model-View-Controller pattern."
      ]
    },
    EA: {
      jobTitle: "Software Engineering Intern @",
      duration: "FEB 2022 - APR 2022",
      desc: [
        "Developed a responsive Python game from scratch, then recreated the game in C++ to compare and contrast differences.",
        "Grew student knowledge on developer products and platforms through hands-on workshops and events and identified local partners to work with and lead project/team building activities.",
        "Collaborated with the team to learn about EA's cybersecurity practices."
      ]
    },
    /*
    "University of Toronto": {
      jobTitle: "Research Engineer @",
      duration: "MAY 2021 - SEPT 2021",
      desc: [
        "Developing and researching an NLP-based framework using state-of-the-art tools like Spacy and Stanza to facilitate the derivation of requirements from health data by leveraging syntactic dependencies, entity-recognition and rule-based match-making.",
        " Application selected for DCS Research Award ($4,000) as part of the ”Visualizing Privacy Analysis Results” project led by Professor Marsha Chechik."
      ]
    },
  */
    // Centivizer: {
    //   jobTitle: "Research Developer @",
    //   duration: "SEPT 2019 - APR 2020",
    //   desc: [
    //     "Researched and developed interactive and neural-activation technologies to stimulate physical and cognitive functions in order to slow the progression of neurodegenerative disorders.",
    //     "Leveraged WebRTC to develop and maintain a Node.js online video-streaming platform in real-time competitive-mode games to research the effects of active stimulation for those suffering from dementia."
    //   ]
    // },
    // TDSB: {
    //   jobTitle: "Software Engineer @",
    //   duration: "SEPT 2019 - DEC 2020",
    //   desc: [
    //     "Co-developed homework management software integrable with Google Classroom by utilizing the Python’s Flask micro-framework for the back-end API and Vue.js for the front-end UI, in order to translate business requirements into a functional full-stack application."
    //   ]
    // },
    /*"Orange Gate": {
      jobTitle: "Software Engineering Intern @",
      duration: "MAY 2019 - AUG 2019",
      desc: [
        "Developed a Node.js smart home system through Facebook’s Messenger integrated with Bocco sen- sors and other smart devices (Nest camera, TPLink smart plugs) to derive conclusions about the current state of the home",
        "Identified continuous improvements in data quality, design reports and coding activities, presenting results and findings to internal business stakeholders.",
        "Relevant technologies/tools used: DialogFlow, Vision, AutoML, Messenger Bot API, MongoDB."
      ]
    }
  */
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation={!isHorizontal ? "vertical" : null}
        variant={isHorizontal ? "fullWidth" : "scrollable"}
        value={value}
        onChange={handleChange}
        className={classes.tabs}
      >
        {Object.keys(experienceItems).map((key, i) => (
          <Tab label={isHorizontal ? `0${i}.` : key} {...a11yProps(i)} />
        ))}
      </Tabs>
      {Object.keys(experienceItems).map((key, i) => (
        <TabPanel value={value} index={i}>
          <span className="joblist-job-title">
            {experienceItems[key]["jobTitle"] + " "}
          </span>
          <span className="joblist-job-company">{key}</span>
          <div className="joblist-duration">
            {experienceItems[key]["duration"]}
          </div>
          <ul className="job-description">
            {experienceItems[key]["desc"].map(function (descItem, i) {
              return (
                <FadeInSection delay={`${i + 1}00ms`}>
                  <li key={i}>{descItem}</li>
                </FadeInSection>
              );
            })}
          </ul>
        </TabPanel>
      ))}
    </div>
  );
};

export default JobList;
